/* PedidosHistoricos.css */

/* Contenedor principal para pedidos históricos */
.pedidos-historicos-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    margin: 0;
    box-sizing: border-box;
    height: auto !important;
    overflow: visible !important;
}

/* Contenedor para scroll horizontal en la tabla (si la tabla es muy ancha) */
.table-container {
    width: 100%;
    overflow-x: auto;
}

/* Contenedor de filtros */
.filtros-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.filtros-container input,
.filtros-container select {
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 200px;
}

/* Tabla de Pedidos Históricos */
.pedidos-historicos-tabla {
    width: 100%;
    border-collapse: collapse;
    margin: 0;
}

.pedidos-historicos-tabla th,
.pedidos-historicos-tabla td {
    padding: 10px;
    border: 1px solid #ccc;
    text-align: left;
}

.pedidos-historicos-tabla th {
    background-color: #f4f4f4;
}

.pedidos-historicos-tabla tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Ajustes globales para evitar márgenes y padding en general */
html, body {
    margin: 0;
    padding: 0;
    height: auto !important;
    overflow: visible !important;
}

/* ---------- MODO OSCURO ---------- */
body.dark-mode .pedidos-historicos-container {
    background-color: #333333;
    color: #e0e0e0;
}

body.dark-mode .filtros-container input,
body.dark-mode .filtros-container select {
    background-color: #333333;
    color: #e0e0e0;
    border: 1px solid #333333;
}

body.dark-mode .pedidos-historicos-tabla {
    background-color: #333333;
    color: #e0e0e0;
}

body.dark-mode .pedidos-historicos-tabla th,
body.dark-mode .pedidos-historicos-tabla td {
    border: 1px solid #333333;
}

body.dark-mode .pedidos-historicos-tabla th {
    background-color: #333333;
    color: #e0e0e0;
}

body.dark-mode .pedidos-historicos-tabla tr:nth-child(even) {
    background-color: #333333;
}

body.dark-mode .pedidos-historicos-tabla tr:hover {
    background-color: #333333;
}
.table-container {
    max-height: 75vh; /* Ajusta el valor según tus necesidades */
    overflow-x: auto;
    overflow-y: auto;
  }

  