/* Pedidos.css */

/* Estilos generales para la vista de Pedidos */
table {
    width: 100%;
    border-collapse: collapse;
    font-size: 14px; /* Tamaño de fuente general */
}

table th,
table td {
    text-align: center;
    padding: 5px; /* Espaciado dentro de las celdas */
    border: 1px solid #ddd;
}

table th {
    background-color: #f2f2f2;
    font-weight: bold;
    font-size: 14px; /* Tamaño de fuente para encabezados */
}

table tr:nth-child(even) {
    background-color: #f9f9f9;
}

table tr:hover {
    background-color: #f1f1f1;
}

table img {
    display: block;
    margin: 0 auto;
    height: 30px; /* Tamaño de imagen a 30px */
    width: 30px;
    object-fit: contain;
}

/* Ajustar ancho de columnas específicas */
table th:nth-child(4),
table td:nth-child(4), /* Teléfono */
table th:nth-child(8),
table td:nth-child(8), /* Producto1 */
table th:nth-child(9),
table td:nth-child(9), /* Producto2 */
table th:nth-child(10),
table td:nth-child(10)  /* Movil */ {
    width: 100px;
}

/* La columna "Dirección" se ajusta automáticamente */
table th:nth-child(7),
table td:nth-child(7) {
    width: auto;
}

/* Estilos para inputs y selects dentro de la tabla */
td input[type="text"],
td select {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: none;
    box-sizing: border-box;
}

td input[type="text"]:focus,
td select:focus {
    outline: none;
    border: 1px solid #ccc;
}

/* Eliminar borde de la celda que contiene los botones */
table td:last-child {
    border: none;
    padding: 5px;
}

/* Mantener el borde derecho de la columna "Tiempo" */
table td:nth-last-child(2) {
    border-right: 1px solid #ddd;
}

/* Centrar y espaciar los botones */
table td:last-child img {
    display: inline-block;
    margin: 0 5px;
}

/* Ajuste para inputs de texto */
input[type="text"] {
    font-size: 14px;
}

/* Estilos para el título */
h1 {
    font-size: 20px;
}

/* Fuente reducida para celdas de la tabla */
table td,
table th {
    font-size: 12px;
}

/* Contenedor de la sección de pedidos */
.pedidos-container {
    margin-left: 20px;
    padding: 0;
}

/* ---------- MODO OSCURO ---------- */
body.dark-mode .pedidos-container {
    background-color: #333333;
    color: #e0e0e0;
}

body.dark-mode table {
    background-color: #333333;
    color: #e0e0e0;
}

body.dark-mode table th {
    background-color: #333333;
    color: #e0e0e0;
}

body.dark-mode table tr:nth-child(even) {
    background-color: #333333;
}

body.dark-mode table tr:hover {
    background-color: #333333;
}

body.dark-mode td input[type="text"],
body.dark-mode td select {
    background-color: #333333;
    color: #e0e0e0;
    border: 1px solid #333333;
}

body.dark-mode td input[type="text"]:focus,
body.dark-mode td select:focus {
    border: 1px solid #333333;
}

/* Ajuste del popup en modo oscuro */
body.dark-mode .popup {
    background: #333333;
    color: #e0e0e0;
    box-shadow: 0 2px 8px rgba(51, 51, 51, 0.5);
}

/* Popup en modo claro */
.popup {
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    color: #000;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.26);
    z-index: 1000;
    text-align: center;
    border-radius: 4px;
}

.popup p {
    margin: 0;
    margin-bottom: 10px;
}

/* Botón (base, modo claro) - aquí lo pones verde */
.popup-button {
    margin-top: 10px;
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background-color: #28a745; /* verde */
    color: #fff;
    border-radius: 4px;
}

/* ---------- MODO OSCURO ---------- */
body.dark-mode .popup {
    background: #333333;
    color: #e0e0e0;
    box-shadow: 0 2px 8px rgba(51, 51, 51, 0.5);
}

body.dark-mode .popup-button {
    background-color: #28a745; /* verde también en oscuro */
    color: #fff;
}

/* Aseguramos que html y body se expandan según el contenido para permitir scroll completo */
html, body {
    margin: 0;
    padding: 0;
    height: auto !important;
    overflow: visible !important;
}
body:not(.dark-mode) .pedidos-container,
body:not(.dark-mode) h1,
body:not(.dark-mode) table,
body:not(.dark-mode) table th,
body:not(.dark-mode) table td {
  color: #000; /* Color negro */
}
