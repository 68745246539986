/* googleMapsOverrides.css */

/* Resetear estilos de los elementos internos del InfoWindow (si se usan) */
.gm-style-iw-ch {
    margin: 0 !important;
    padding: 0 !important;
  }
  .gm-style-iw {
    font-family: Arial, sans-serif !important;
    font-size: 14px !important;
    color: #000 !important;
  }
  .gm-style-iw-d {
    margin: 0 !important;
    padding: 0 !important;
  }
  
  /* Tooltip permanente simple */
  .my-marker-tooltip {
    position: relative;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 3px 6px;
    border-radius: 4px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #000;
    transform: translate(-32%, -230%);
    white-space: nowrap;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    width: 28px;
  }
  
  /* Tooltip detallado */
  .custom-info-window {
    position: relative;
    background: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #000;
    width: 200px;
  }
  .custom-info-window .close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    color: #000;
  }
  .custom-info-window span {
    display: block;
    margin-bottom: 5px;
  }
  