.main-content,
.main-content.gps {
    padding: 0; /* Elimina padding */
    height: 100vh; /* Asegura que ocupe toda la pantalla */
    overflow-x: auto; /* Permite scroll horizontal */
    overflow-y: hidden; /* Mantiene oculto el scroll vertical si es necesario */
}
.table-wrapper {
    width: 100%;
    overflow-x: auto;
  }
  