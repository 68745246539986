/* Layout principal */
.layout {
    display: flex;
    flex-direction: row;
    min-height: 100vh;
    width: 100%;
    background-color: #f5f5f5;
}

/* Sidebar (en escritorio) */
.sidebar {
    width: 450px;
    background-color: #333;
    color: white;
    padding: 20px;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    overflow-y: auto;
    z-index: 100;
    transition: width 0.3s ease;
}

/* Sidebar colapsado */
.layout.collapsed .sidebar {
    width: 100px;
}

/* Main content */
/* Sin height ni overflow para permitir scroll completo en la página */
.main-content {
    margin-left: 300px;
    padding: 20px;
    width: calc(100% - 250px);
    transition: margin-left 0.3s ease;
}

/* Main content cuando el sidebar está colapsado */
.layout.collapsed .main-content {
    margin-left: 140px;
    width: calc(100% - 80px);
}

/* Botón de colapsar/expandir */
.toggle-btn {
    margin-bottom: 20px;
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
}

/* Elimina padding en vistas de mapas */
.main-content.no-padding {
    padding: 0;
}

/* Modo Oscuro */
body.dark-mode .layout {
    background-color: #333333;
}

body.dark-mode .sidebar {
    background-color: #333333;
    color: #e0e0e0;
}

body.dark-mode .main-content {
    background-color: #333333;
    color: #e0e0e0;
}

body.dark-mode .toggle-btn {
    background-color: #e74c3c;
    color: #e0e0e0;
}

/* Responsividad */
@media (max-width: 768px) {
    .layout {
       flex-direction: column;
    }

    .sidebar {
        width: 100%;
        position: relative;
        height: auto;
    }

    .layout.collapsed .sidebar {
        width: 100%;
    }

    .main-content {
        margin-left: 0;
        width: 100%;
        padding: 10px;
    }

    .layout.collapsed .main-content {
        margin-left: 0;
        width: 100%;
    }
}
