/* RutaMoviles.css */

/* Contenedor Principal */
.container {
  height: 100vh;
  width: 100%; /* Ancho completo del contenedor padre */
  max-width: 3200px; /* Ancho máximo deseado */
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  position: relative;
  font-family: Arial, sans-serif;
  box-sizing: border-box; /* Incluye padding y border en el ancho total */
  padding: 0 0px; /* Espacio interno para evitar que el contenido toque los bordes */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Asegura que el contenido esté alineado al inicio */
}

/* Panel de Filtros */
.filter-panel {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  width: 90%;
  max-width: 320px;
}

.filter-panel h4 {
  margin-top: 0;
  text-align: center;
  color: #333;
}

.filter-group {
  margin-bottom: 12px;
}

.date-picker-group {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.time-group {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.input {
  width: 100%;
  padding: 6px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

.time-input {
  max-width: 110px;
}

.btn {
  width: 100%;
  background-color: #007bff;
  color: #fff;
  padding: 8px 0;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
}

.status-msg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.9);
  padding: 12px 20px;
  border-radius: 4px;
  font-size: 18px;
  color: #333;
  z-index: 1000;
}

.error {
  color: red;
}

/* Responsividad para pantallas pequeñas */
@media (max-width: 768px) {
  .filter-panel {
    width: 95%;
    right: 2.5%;
    top: 5px;
    padding: 10px;
  }

  .btn {
    font-size: 14px;
  }

  .date-picker-group {
    flex-direction: column;
  }

  .time-group {
    flex-direction: column;
    align-items: flex-start;
  }

  .time-input {
    max-width: 100%;
  }
}

.mobiles-select {
  overflow-y: hidden;
  scrollbar-width: none; /* Para Firefox */
  -ms-overflow-style: none; /* Para Internet Explorer 10+ */
}

.mobiles-select::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

/* Estilo para la tarjeta del progreso dentro de filter-panel */
.progress-section {
  background-color: #fff;
  border-radius: 8px;
  padding: 15px;
  margin-top: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile-details {
  margin-top: 10px;
}

.mobile-card {
  background-color: #f9f9f9;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 14px; /* Reducir tamaño de fuente para compactar */
}

.mobile-card p {
  display: flex;
  justify-content: space-between;
  margin: 0;
}

/* Estilo para la tarjeta de resumen */
.summary-card {
  background-color: #f1f1f1;
  padding: 15px;
  margin-top: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.summary-card p {
  display: flex;
  justify-content: space-between;
}

/* Estilo para el mapa */
.map-container {
  width: 100%;
  flex-grow: 1; /* Asegura que el mapa ocupe todo el espacio restante */
  height: 100%; /* Hace que el mapa ocupe toda la altura */
}

.leaflet-container {
  width: 100% !important;
  height: 100% !important; /* Asegura que Leaflet ocupe todo el contenedor */
}
