/* src/styles/Sidebar.css */

/* Contenedor principal en escritorio */
.sidebar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 150px;
  height: 100%;
  background-color: #333;
  color: white;
  z-index: 100;
  transition: all 0.3s ease;
  overflow: hidden; /* Evita scroll lateral en desktop */
}

/* Encabezado del sidebar (Menú + hamburguesa) */
.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #444;
  background-color: #333;
}

.sidebar-header h2 {
  margin: 0;
  font-size: 24px;
}

/* Botón hamburguesa */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 24px;
  cursor: pointer;
}

.hamburger .bar {
  height: 3px;
  width: 100%;
  background-color: #fff;
  border-radius: 2px;
}

/* Contenido del sidebar (opciones) */
.sidebar-content {
  /* Quitamos padding-top en móvil para que no se vea medio ítem */
  padding: 0 20px 20px 20px;
  height: calc(100% - 65px);
  overflow-y: auto;
  transition: max-height 0.3s ease;
}

.sidebar-content ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-content ul li {
  margin-bottom: 10px;
}

.sidebar-content ul li a {
  color: white;
  text-decoration: none;
  display: block;
  padding: 10px;
  border-radius: 5px;
}

.sidebar-content ul li a.active,
.sidebar-content ul li a:hover {
  background-color: #555;
}

.logout-btn {
  margin-top: 20px;
  width: 100%;
  padding: 10px;
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.dark-mode-toggle {
  margin-top: 20px;
}

/* Responsive (móvil) */
@media (max-width: 768px) {
  .sidebar-container {
    width: 100%;
    height: auto;
    position: static;
    overflow: visible;
  }
  
  .hamburger {
    display: flex;
  }

  /* Por defecto oculto */
  .sidebar-content {
    max-height: 0;
    overflow-y: hidden;
    height: auto;
    padding: 0 20px; /* Sin padding-top para que no asome medio ítem */
  }

  /* Cuando está abierto */
  .sidebar-content.open {
    max-height: none; /* Se expande todo */
    overflow-y: auto;
  }
}
