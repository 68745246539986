/* Fondo general de la página */
body {
  background-color: #333333;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  padding: 0;
  color: #eee;
}

/* Contenedor del login */
.login-container {
  max-width: 400px;
  margin: 5% auto;
  background-color: #444;
  border-radius: 8px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
  padding: 30px 20px;
  text-align: center;
}

/* Título */
.login-container h2 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #fff;
}

/* Formulario */
.login-form {
  text-align: center; /* Centra el contenido del formulario */
  padding: 0 10px;
}

/* Labels */
.login-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #ddd;
}

/* Inputs centrados */
.login-form input[type="text"],
.login-form input[type="password"] {
  width: 90%; /* Ajusta el ancho de los inputs */
  padding: 12px;
  border: 1px solid #666;
  border-radius: 5px;
  margin: 0 auto 15px;
  font-size: 1rem;
  background-color: #555;
  color: #fff;
  display: block;
}

/* Checkbox container */
.login-form .checkbox-container {
  margin-top: 10px;
  text-align: left;
  font-size: 0.9rem;
}

/* Checkbox label */
.login-form .checkbox-container label {
  color: #ccc;
}

/* Botón */
.login-form button {
  width: 90%;
  padding: 12px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  margin: 0 auto;
  display: block;
}

.login-form button:hover {
  background-color: #43a047;
}

/* Mensaje de error */
.error-message {
  color: #ff8080;
  margin-bottom: 10px;
}

/* Responsividad */
@media (max-width: 768px) {
  .login-container {
    max-width: 90%;
    padding: 20px;
  }
  
  .login-container h2 {
    font-size: 1.5rem;
  }
  
  .login-form input[type="text"],
  .login-form input[type="password"] {
    padding: 10px;
    font-size: 0.9rem;
  }
  
  .login-form button {
    padding: 10px;
    font-size: 14px;
  }
}
